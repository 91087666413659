<template>
  <div class="m-container">
    <div class="faq">
      <h1>Hello, How can we help you ?</h1>

      <!-- search faq -->
      <div class="search-faq">
        <form class="position-relative" @click.prevent="getQuestions">
          <img src="@/assets/images/search-icon.png" alt="" class="search-icon">
          <input type="text" placeholder="Type the keywords to find answers ..." v-model="form.search">
          <button type="submit">search</button>
        </form>
        <button type="button" @click.prevent="resetQuestion">Reset</button>
      </div>

      <p class="choose-cat">Or choose a category to quickly find the help you need. </p>

      <div class="row faq-blocks justify-center">
        <div class="col-md-6 col-lg-3" v-for="category in categories" :key="category.id">
          <div class="faq-block" :class="{'active': selectedCat == category.id}"
               @click="selectedCat == category.id? selectedCat = null: selectedCat = category.id">
            <img :src="category.logo" alt="">
            <p>{{ category.name }}</p>
          </div>
        </div>

      </div>

      <div class="faq-block-content-wrapper">
        <div class="faq-block-content" v-for="category in categories" :key="category.id"
             v-if="selectedCat == category.id">
          <h4>{{ category.name }}</h4>
          <p> {{ category.description }} </p>
        </div>

      </div>

      <!-- questions -->
      <div class="questions">
        <vs-collapse accordion v-if="questions.length > 0">
          <vs-collapse-item v-for="question in questions" :key="question.id">
            <div slot="header">
              {{ question.question }}
            </div>
            {{ question.answer }}
          </vs-collapse-item>
        </vs-collapse>

        <h3 v-else class="text-center">There are no questions</h3>
      </div>


      <!-- sponsors -->
      <!--      <div class="row justify-center partners">
              <div class="col-sm-4 col-md-3 col-lg-2">
                <img src="@/assets/images/partner1.png" alt="">
              </div>
              <div class="col-sm-4 col-md-3 col-lg-2">
                <img src="@/assets/images/partner2.png" alt="">
              </div>
              <div class="col-sm-4 col-md-3 col-lg-2">
                <img src="@/assets/images/partner3.png" alt="">
              </div>
              <div class="col-sm-4 col-md-3 col-lg-2">
                <img src="@/assets/images/partner4.png" alt="">
              </div>
              <div class="col-sm-4 col-md-3 col-lg-2">
                <img src="@/assets/images/partner5.png" alt="">
              </div>
              <div class="col-sm-4 col-md-3 col-lg-2">
                <img src="@/assets/images/partner6.png" alt="">
              </div>
            </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      selectedCat: null,
      questions: [],
      categories: [],
      form: {
        search: null
      }
    }
  },
  mounted() {
    this.$API.faq.getCategories()
      .then(response => {
        this.categories = response
      });

    this.getQuestions()

  },
  watch: {
    'selectedCat'() {
      this.getQuestions()
    }
  },
  methods: {
    getQuestions() {
      this.$API.faq.getQuestions({type: 'international', category_id: this.selectedCat, ...this.form})
        .then(response => {
          this.questions = response
        })
    },
    resetQuestion() {
      this.form.search = null;
      this.getQuestions();
    }
  }
}
</script>

<style scoped>

</style>
